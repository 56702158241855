import React, { useEffect } from "react"
import styled from "styled-components"
import { FullWidthBg, GridContainer } from "../../utilities/Containers"
import { Card } from "../../elements"
import { background, h1, h2, headingMarBottom, textColor } from "../../utilities"
import { useAnimation } from "framer-motion"
import {
  CareerPlanAnim,
  CVAnim,
  InterviewPrepAnim,
  OtherServAnim,
  SmileAnim,
  WorkshopAnim
} from "../../Animations/SvgAnimations"
import { useInView } from "react-intersection-observer"

const ServiceContainer = styled(FullWidthBg)`
  h2 {
    font-size: ${h1};
    font-weight: 700;
    padding-top: 4.5rem;
    margin: 0;
  }

  padding-bottom: 6rem;

  hr {
    width: 70%;
    margin: ${headingMarBottom} auto;
  }
`

const ServiceCard = styled(Card)`
  background: ${background};
  width: 20rem;

  p {
    margin-bottom: 1.5rem;
    color: ${textColor};
  }

  h2 {
    //margin-top: 0.5rem;
    padding-top: 0;
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    //padding: 1rem 0;
  }

  img {
    height: 4rem;
    margin-bottom: 1.5rem;
  }

  .svg-inline--fa {
    font-size: 6rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 768px) {
    img {
      height: 2rem;
    }
  }
`

const Services = () => {
  return (
    <ServiceContainer>
      {/* <h2>Services</h2> */}
      {/* <hr /> */}
      <GridContainer modifiers="threeCol">
        <FromScratch />
        <ECommerce />
        <SeoOptimization />
        <Blog />
        <Wordpress />
        <CodeImplementation />
      </GridContainer>
    </ServiceContainer>
  )
}

export default Services

const variants = {
  visible: { opacity: 1, y: 0, transition: { staggerChildren: 1 } },
  hidden: { opacity: 0, y: 300 }
}

const FromScratch = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <ServiceCard
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
    >
      <h2>Web Design</h2>
      <CareerPlanAnim />
      <p>
        {`With your input all along the way we'll design a one of a kind, mobile ready site from scratch that matches your business ideals and marketing strategy`}
      </p>
      {/*<Link to="/coaching-services#career-planning">*/}
      {/*  {' '}*/}
      {/*  <Button modifiers="small">Learn More</Button>*/}
      {/*</Link>*/}
    </ServiceCard>
  )
}

const ECommerce = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <ServiceCard
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
    >
      <h2>E-Commerce Integration</h2>
      <CVAnim controls={controls} />
      {/*<img alt="CV-icon" src={clipboard} />*/}
      <p>
        {`Seamlessly integrate an online shopping experience, booking form, or paid content into your site. `}
      </p>
      {/*<Link to="/coaching-services#CV-Application-Support">*/}
      {/*  {' '}*/}
      {/*  <Button modifiers="small">Learn More</Button>*/}
      {/*</Link>*/}
    </ServiceCard>
  )
}

const SeoOptimization = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <ServiceCard
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
    >
      <h2>SEO</h2>
      <InterviewPrepAnim />
      {/*<img alt="Interview-icon" src={handshake} />*/}
      <p>
        {`I will initate a robust long term SEO plan to get help get your site on page one, and help give you content ideas to further solidify your site's SEO.`}
      </p>
      {/*<Link to="/coaching-services#interview-prep">*/}
      {/*  {' '}*/}
      {/*  <Button modifiers="small">Learn More</Button>*/}
      {/*</Link>*/}
    </ServiceCard>
  )
}

const SecondrowSVGs = {
  visible: {
    opacity: 1,
    y: 0
  },
  hidden: { opacity: 0, y: -300 }
}

const Blog = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <ServiceCard
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
    >
      <h2>Blog Engine</h2>
      <WorkshopAnim />
      {/*<img src={users} />*/}
      <p>
        Maintaining a regular a blog not only gives your users and potential customers additional and much appreciated
        educational content, but drives extra traffic and gives an extra SEO boost that can give you that edge.</p>
      {/*<Link to="/workshops">*/}
      {/*  {' '}*/}
      {/*  <Button modifiers="small">Learn More</Button>*/}
      {/*</Link>*/}
    </ServiceCard>
  )
}

const Wordpress = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <ServiceCard
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
    >
      <h2>Wordpress</h2>
      <SmileAnim />
      <p>
        {`Building your site in Wordpress allows you to update the site on your own. If you want to make a small change in your about page, or update a section, you can do it yourself!`}
      </p>
      {/*<Button modifiers="small">Learn More</Button>*/}
    </ServiceCard>
  )
}

const CodeImplementation = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <ServiceCard
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
    >
      <h2>React App</h2>
      <OtherServAnim />

      <p>
        {`I can also build your site as a React Application. It has significant speed benefits over Wordpress, is more economical to run, and is extremely flexible, powerful and scalable. Although it requires a developer to make changes/update content.  `}
      </p>
      {/*<Button modifiers="small">Learn More</Button>*/}
    </ServiceCard>
  )
}
