import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Layout from '../components/layouts/Layout'
import SEO from '../components/seo'
import { GridContainer, WorkWith } from '../components/layouts'
import { above, below, FlexWrapper, h3, textColor } from "../components/utilities"
import { Button } from '../components/elements'
import Process from '../components/layouts/HomeComps/Process'
// import { TreeAnim } from '../components/Animations/SvgAnimations'
import Services from '../components/layouts/HomeComps/Services'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Ogham } from '../components/Animations/SvgAnimations'
import Img from 'gatsby-image'
import logo from '../images/logo.png'

const HomeContainer = styled(GridContainer)`
  ${below.small`
    grid-template-columns: 1fr 1fr;
    text-align: left;
    height: auto;
    padding-top: 6rem;
    padding-right:0.7rem; 
    > div:nth-child(2) {
      margin: 2rem 0;
    } 
    h1{
    padding-top: 4rem;
    }   
  `}
  padding-bottom: 2rem;
  h1 {
    font-size: 4rem;
    font-weight: 700;
    color: ${textColor};
  }

  h2 {
    ${below.med`text-align: left;`}
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/cartoon-tom/" }) {
        childImageSharp {
          fixed(height: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout
      css={`
        padding-top: 0 !important;
      `}
    >
      <SEO title="Website Design" image={logo} />
      <HomeContainer>
        <Ogham />
        <FlexWrapper
          css={`
            ${below.small`align-items: flex-start;`};
          `}
        >
          {/*<h2>{`Hi. I'm Joan.`} </h2>*/}
          <motion.h1
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // transition={{ duration: 3, delay: 1 }}
          >
            <span>TC</span>{' '}
            <span
              css={`
                ${below.small`display:inline-block`}
              `}
            >
              web design
            </span>
          </motion.h1>
          <br />
          <div
            css={`
              h2 {
                margin-bottom: 0.5rem;
                font-size: ${h3};
                font-weight: 400;
                ${above.small`display:inline;`}
              }
            `}
          >
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 3, delay: 1 }}
            >
              {' '}
              unique.
              {''}
            </motion.h2>
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 3, delay: 0.5 }}
            >
              {' '}
              beautiful.
              {''}
            </motion.h2>
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 3, delay: 0.5 }}
            >
              {' '}
              functional.
              {''}
            </motion.h2>
          </div>
          <br />
          {/*<Button>*/}
          {/*  <Link to="/booking"> Book a call</Link>*/}
          {/*</Button>*/}
        </FlexWrapper>
        {/* <FlexWrapper>
            {/* <TreeAnim /> */}
        {/* </FlexWrapper> */}
      </HomeContainer>
      <Services />
      <GridContainer
        css={`
          padding-bottom: 3rem;
          a button {
            margin-top: 2.5rem;
          }
        `}
      >
        <h2>About Me</h2>
        <GridContainer
          css={`
            padding: 0;
            text-align: left;

            img {
              border-radius: 20px;
              margin-bottom: 3rem;
            }

            grid-gap: 1rem;
          `}
          modifiers={['twoCol']}
        >
          <div>
            <p>Hello. My name is Tom.</p>
            <p>
              I am a web designer and developer based in Kilkenny, Ireland specialising in Wordpress and React development.
            </p>
            <p>
              I enjoy good coffee, <a href="https://outsidetheframe.photography">photography</a> and creating things (like
              websites). Oh and I also play the
              <a href="https://www.tomcaraher.com"> saxophone.</a>
            </p>
          </div>
          <Img fixed={data.file.childImageSharp.fixed} />

          {/*</div>*/}
        </GridContainer>
          <Button to="/about">Learn More About Me</Button>
      </GridContainer>
      <Process />
      <WorkWith />
      {/*<Testimonials />*/}
    </Layout>
  )
}

export default IndexPage
